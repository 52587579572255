<template>
    <div class="Box-item-container">
        <div class="header-container">
            <p class="title">{{ layoutFor === 'courses' ? 'Courses' : 'Expo'}}</p>
            <!-- <p class="cta">See all</p> -->
        </div>
        <el-scrollbar>
           <div class="content">
               <OpportunityCardItemComponent
               v-for="(item, index) in data"
               :item="item" :key="index"
               :showButton="true"
               />
               <OpportunityCardItemWithDescriptionComponent v-show="layoutFor === 'events'" v-for="(item, index) in cardCount" :key="index"/>
           </div>
        </el-scrollbar>
    </div>
</template>
<script>
// import { ref } from 'vue';

import OpportunityCardItemComponent from '@/modules/opportunity-children/components/OpportunityCardItemComponent.vue';
import OpportunityCardItemWithDescriptionComponent from '@/modules/opportunity-children/components/OpportunityCardItemWithDescriptionComponent.vue';

export default {
  components: {
    OpportunityCardItemComponent,
    OpportunityCardItemWithDescriptionComponent
  },
  props: ['layoutFor', 'data'],
};
</script>
<style lang="scss" scoped>
.Box-item-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 480px;
    width: 100%;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        P {
            margin: 0;
        }

        .title {
            font-family: Mulish;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: left;
            color: #0C0F4A;
            margin: 0;
        }

        .cta {
            font-family: Mulish;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
            text-align: right;
            color: #FAA100;
        }
    }

    .el-scrollbar {
        background: #fff;
        width: 100%;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 0;

        :deep(.el-scrollbar__view) {
           padding: 1rem;
        }
        // height: calc(100% - 200px);
        .content {
            display:flex;
            flex-direction: column;
            gap:1rem;
            padding-bottom: 1rem;
        }
    }
}
</style>
