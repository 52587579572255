<template>
    <el-card class="card-item">
        <div class="item-content">
           <el-image
                class="attachment-image"
                fit="cover"
                :src="url"
                :preview-src-list="srcList"
                :append-to-body="true"
            >
                <template v-slot:error>
                <div class="image-slot">
                    <el-icon><Picture /></el-icon>
                </div>
                </template>
            </el-image>
            <div class="item-details">
                <div class="title-wrapper">
                    <p class="title">iknowa Air Source Heat Pump Installation Course</p>
                    <p class="label"><span><img src="@/assets/icons/clock.svg" alt=""></span> 45 mins - 4 lessons</p>
                </div>
                <p class="description">This course is designed to allow qualified plumbers and heating engineers to gain the knowledge and skills needed to install Air source heat pumps either by installing new installations or retro fitting existing installations.</p>
            </div>
            <el-icon :width="20"><ArrowRightBold/></el-icon>
        </div>
    </el-card>
</template>
<script>
import { ArrowRightBold, Picture } from '@element-plus/icons';
import { ref } from 'vue';

export default {
  components: {
    Picture,
    ArrowRightBold
  },
  props: ['isFlexRow'],
  setup() {
    /* eslint-disable global-require */
    const url = ref(`${require('@/assets/images/sample-image-three.jpg')}`);
    const srcList = ref([
      `${require('@/assets/images/sample-image-three.jpg')}`,
    ]);
    /* eslint-disable global-require */
    return {
      url,
      srcList
    };
  }
};
</script>
<style lang="scss" scoped>
.el-card.is-always-shadow {
    box-shadow: none;
}
.el-card {
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    border: none;

    :deep(.el-card__body) {
        padding: 0;
    }

    .item-content {
        display:flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: flex-start;

        .el-image {
            width: 77px;
            height: 77px;
            border-radius: 10px;
            min-width: 77px;
        }
        .item-details {
                display:flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                gap: 3rem;

            p {
                margin: 0;
            }

            .title-wrapper {
                display:flex;
                flex-direction: column;
                gap: .5rem;
                align-items: flex-start;
                justify-content: flex-start;
                width: 30%;

                p {
                    margin: 0;
                }

                .title {
                    font-family: Mulish;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                }

                .label {
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: rgba(12, 15, 74, 0.5);
                    display:flex;
                    gap: .5rem;
                    align-items: center;
                    justify-content: center;

                    img {
                    object-fit: cover;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    }
                }
            }

            .description {
                font-family: Mulish;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.4000000059604645px;
                text-align: left;
                color: rgba(12, 15, 74, 0.8);
                width: 60%;
            }
        }
        .el-icon {
            display:flex;
        }
    }
}
</style>
