<template>
    <div class="recommendation-container">
        <p class="title">Recommendation</p>
        <div class="item-container">
          <OpportunityCardItemComponent
            v-for="(item, index) in data"
            :item="item" :key="index"
            :showButton="true"
            />
        </div>
    </div>
</template>
<script>
import OpportunityCardItemComponent from '@/modules/opportunity-children/components/OpportunityCardItemComponent.vue';

export default {
  components: {
    OpportunityCardItemComponent
  },
  props: ['data'],
};
</script>
<style lang="scss" scoped>
.recommendation-container {
   display:flex;
   flex-direction: column;
   gap: 1.5rem;

   .title {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
   }

   .item-container {
       display:flex;
       gap: 1rem;
       width: 100%;
       overflow: auto;
   }
}
</style>
