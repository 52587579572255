<template>
    <OpportunityLayoutSlot>
           <OpportunityHeroComponent layoutFor="events"/>
           <OpportunityNoContentComponent v-if="isEmpty"/>
           <OpportunityRecommendationComponent :data="events"/>
           <OpportunityBoxItemsComponent layoutFor="events" :data="events"/>
    </OpportunityLayoutSlot>
</template>
<script>
import { ref } from 'vue';

import OpportunityBoxItemsComponent from '@/modules/opportunity-children/components/OpportunityBoxItemsComponent.vue';
import OpportunityHeroComponent from '@/modules/opportunity-children/components/OpportunityHeroComponent.vue';
import OpportunityLayoutSlot from '@/modules/opportunity-children/components/OpportunityLayoutSlot.vue';
import OpportunityNoContentComponent from '@/modules/opportunity-children/components/OpportunityNoContentComponent.vue';
import OpportunityRecommendationComponent from '@/modules/opportunity-children/components/OpportunityRecommendationComponent.vue';

export default {
  components: {
    OpportunityHeroComponent,
    OpportunityLayoutSlot,
    OpportunityNoContentComponent,
    OpportunityRecommendationComponent,
    OpportunityBoxItemsComponent
  },
  setup() {
    const isEmpty = ref(false);
    const event = {
      title: 'iknowa Air Source Heat Pump Installation Course',
      about: 'This course is designed to allow qualified plumbers and heating engineers to gain the knowledge and skills needed to install Air source heat pumps either by installing new installations or retro fitting existing installations. Completing this course will allow installers to apply and join the MCS Scheme.',
      aims: 'This sfsdf dfdsfsd sdfsdfdsf sdfsdfsd fsd fdsfds fsfsd sdfsd dfs sdfds sdfsd sdfsd sdf sdfds dsf sdfds sfsdf dfdsfsd sdfsdfdsf sdfsdfsd fsd fdsfds fsfsd sdfsd dfs sdfds sdfsd sdfsd sdf sdfds dsf sdfds qualification prepares learners who are looking towards a career in Air Source Heat Pump Installation and gaining the necessary skills for the design, installation, testing, commissioning, handover, servicing and fault-finding of ground and air source heat pump systems in accordance with the latest NOS/QCF criteria and MCS scheme requirements.',
      thumbnail: '@/assets/images/sample-image-three.jpg',
      image: '@/assets/images/sample-picture.jpg'
    };
    const events = ref(Array(7).fill().map((_, i) => ({ ...event, id: i, title: `${event.title} (${i + 1})` })));

    return {
      isEmpty,
      events
    };
  }
};
</script>
<style lang="scss" scoped>
</style>
